import React from "react";
import { InView } from "utils/animation";
import Container from "layouts/Container";
import MediaCard from "components/MediaCard";

import "./ProjectGrid.scss";

const ProjectGrid = ({ projects }) => {
  return (
    <Container className="project-grid container--small" noOverflow={false}>
      <div className="project-grid__inner">
        {projects.map((project, index) => (
          <InView key={index}>
            <MediaCard className="project-grid__item a-fade-up-from-bottom" {...project.frontmatter} />
          </InView>
        ))}
      </div>
    </Container>
  );
};

export default ProjectGrid;
