import React from "react";
import { InView, staggerStyle } from "utils/animation";
import ArrowLink from "elements/ArrowLink";

import "./Cta.scss";

const Cta = ({ title, link }) => {
  return (
    <InView className="cta">
      <h2 className="cta__title t-title-2 a-fade-up">{title}</h2>
      <ArrowLink className="a-fade-up" style={staggerStyle(2)} to={link.to}>
        {link.title}
      </ArrowLink>
    </InView>
  );
};

export default Cta;
