import React from "react";
import clsx from "clsx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "@w3f/gatsby-theme-w3f/src/components/default/Link";
import ArrowLink from "elements/ArrowLink";

import "./MediaCard.scss";

const MediaCard = ({ title, description, image, link, className, vertical = false, ...props }) => {
  const imageData = getImage(image);
  return (
    <Link
      to={link.url}
      className={clsx("media-card u-shadow", className, vertical ? "media-card--vertical" : "media-card--horizontal")}
      {...props}
    >
      <div className="media-card__image-wrapper">
        <GatsbyImage style={{ position: "absolute" }} className="media-card__image" image={imageData} alt="" />
      </div>

      <div className="media-card__content">
        <h3 className={clsx("media-card__title", { "t-title-2": !vertical })}>{title}</h3>
        <p className="media-card__text t-sm t-line-break" dangerouslySetInnerHTML={{ __html: description }} />
        <div>
          <hr className="media-card__divider" />
          <ArrowLink to={link.url} className="media-card__link t-light-gray" stretched>
            {link.title}
          </ArrowLink>
        </div>
      </div>
    </Link>
  );
};

export default MediaCard;
